<template>
    <div class="bag-content">
        <el-scrollbar>
            <el-breadcrumb class="genera-breadcrumb" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>直播实训题管理</el-breadcrumb-item>
                <el-breadcrumb-item>福袋管理</el-breadcrumb-item>
                <el-breadcrumb-item>创建福袋</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="top-form">
                <div class="title-item">福袋基本信息填写</div>
                <el-form :model="addForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="福袋名称" prop="bag_name">
                        <el-input v-model="addForm.bag_name" placeholder="2-18个字"></el-input>
                    </el-form-item>
                    <el-form-item label="行业分类" prop="selectCate">
                        <el-cascader
                            v-model="addForm.selectCate"
                            :options="categoryList"
                            :props="categoryProps"
                            clearable
                            style="width: 380px"
                            @change="changeCate"
                        >
                        </el-cascader>
                    </el-form-item>
                    <el-form-item label="倒计时" class="is-required">
                        <el-input-number
                            :min="0"
                            :max="60"
                            :controls="false"
                            :precision="0"
                            v-model="addForm.countdownMin"
                            ref="countdownMin"
                            style="width: 160px"
                        ></el-input-number>
                        <span class="inner-unit">分</span>
                        <el-input-number
                            :min="0"
                            :max="59"
                            :controls="false"
                            :precision="0"
                            v-model="addForm.countdownSec"
                            ref="countdownSec"
                            style="width: 160px"
                        ></el-input-number>
                        <span class="inner-unit">秒</span>
                    </el-form-item>
                    <el-form-item label="参与方式" prop="in_type">
                        <el-select v-model="addForm.in_type" placeholder="请选择参与方式" style="width: 380px">
                            <el-option label="所有观众" value="1"></el-option>
                            <el-option label="仅限粉丝" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="参与口令" prop="command_id">
                        <el-select v-model="addForm.command_id" placeholder="请选择口令进行参与" style="width: 380px">
                            <el-option v-for="item in commandList" :key="item.id" :label="item.title" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="可中奖数" prop="winning_number">
                        <el-input-number
                            :min="1"
                            :controls="false"
                            :precision="0"
                            :max="9999999"
                            v-model="addForm.winning_number"
                            style="width: 380px"
                            placeholder="请输入可中奖数"
                        ></el-input-number>
                        <span class="inner-unit">人</span>
                    </el-form-item>
                </el-form>
            </div>
            <div class="script-content">
                <div class="title-item">福袋数据信息填写</div>
                <el-table
                    :data="addForm.script_data"
                    :header-cell-style="{ fontWeight: '400', color: '#332D42', background: '#F7F8FC', height: '60px' }"
                    :cell-style="{ fontSize: '14px', color: '#332D42', height: '70px' }"
                >
                    <el-table-column prop="动作" label="动作" width="150" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.$index == 0" class="act-box">有/无发布</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="script_content" label="脚本内容" min-width="500">
                        <template slot-scope="scope">
                            <div class="table-script">
                                <div class="left-single" v-if="scope.$index != 0"></div>
                                <el-input
                                    v-model="scope.row.script_content"
                                    :ref="`${scope.$index}_script_content`"
                                    maxlength="200"
                                    show-word-limit
                                    placeholder="2-200字"
                                    style="width: 380px"
                                ></el-input>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="key_word" label="关键词" width="200">
                        <template slot-scope="scope">
                            <el-input
                                v-model="scope.row.key_word"
                                :ref="`${scope.$index}_key_word`"
                                :placeholder="scope.row == addForm.script_data[0] ? '' : '多个用#分割'"
                                maxlength="50"
                                :disabled="scope.$index === 0"
                                style="width: 124px"
                            ></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="miss_key_word" label="错误关键词" width="200">
                        <template slot-scope="scope">
                            <el-input
                                v-model="scope.row.miss_key_word"
                                :ref="`${scope.$index}_miss_key_word`"
                                :placeholder="scope.row == addForm.script_data[0] ? '' : '多个用#分割'"
                                maxlength="50"
                                :disabled="scope.$index === 0"
                                style="width: 124px"
                            ></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="answer_min" label="回答间隔时间" width="250">
                        <template slot-scope="scope">
                            <el-input-number
                                :min="0"
                                :max="60"
                                :controls="false"
                                :precision="0"
                                v-model="scope.row.answer_min"
                                :ref="`${scope.$index}_answer_min`"
                                style="width: 60px"
                            ></el-input-number>
                            <span class="inner-unit">分</span>
                            <el-input-number
                                :min="1"
                                :max="60"
                                :controls="false"
                                :precision="0"
                                v-model="scope.row.answer_sec"
                                :ref="`${scope.$index}_answer_sec`"
                                style="width: 60px"
                            ></el-input-number>
                            <span class="inner-unit">秒</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="turnover_rate" label="流失率" width="150">
                        <template slot-scope="scope">
                            <el-input-number
                                :min="0.01"
                                :max="10"
                                :controls="false"
                                :precision="2"
                                v-model="scope.row.turnover_rate"
                                :ref="`${scope.$index}_turnover_rate`"
                                style="width: 80px"
                            ></el-input-number>
                            <span class="inner-unit">%</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="operation" label="操作" width="150" align="center">
                        <template slot-scope="scope">
                            <el-link v-if="scope.$index == 0" type="primary" :underline="false" @click="addScript(scope.$index)"
                                >添加脚本话术
                            </el-link>
                            <el-link v-if="scope.$index != 0" type="danger" :underline="false" @click="delRow(scope.$index)"
                                >删除
                            </el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="footer-button">
                    <el-button @click="goBack">返回</el-button>
                    <el-button type="primary" @click="onSubmit('ruleForm')">保存</el-button>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import { saveBag, commodityList, getBagCommand, getBagInfo } from "@/utils/apis";

export default {
    name: "CreateBag",
    data() {
        return {
            addForm: {
                bag_name: "",
                category_id: "",
                countdownMin: "",
                countdownSec: "",
                in_type: "",
                command_id: "",
                script_data: [
                    {
                        script_content: "",
                        key_word: "",
                        miss_key_word: "",
                        answer_min: "",
                        answer_sec: "",
                        turnover_rate: "",
                    },
                ],
                selectCate: [],
                winning_number: void 0,
            },
            rules: {
                bag_name: [
                    { required: true, message: "请输入福袋名称", trigger: "blur" },
                    { min: 2, max: 18, message: "长度在 2 到 18 个字符", trigger: "blur" },
                ],
                selectCate: [{ required: true, message: "请选择行业分类", trigger: "change" }],
                countdown: [{ required: true, message: "请输入倒计时", trigger: "blur" }],
                in_type: [{ required: true, message: "请选择参与方式", trigger: "change" }],
                command_id: [{ required: true, message: "请选择口令", trigger: "change" }],
                winning_number: [{ required: true, message: "请输入可中奖数", trigger: "blur" }],
            },
            categoryList: [],
            commandList: [],
            editId: this.$route.query.id || null,
            categoryProps: {
                value: "category_id",
                label: "category_name",
                children: "children",
            },
        };
    },
    mounted() {
        this.getCategoryList();
        this.getBagCommandList();
    },
    methods: {
        getCategoryList() {
            let param = {
                paging: "0",
            };
            commodityList(param).then((res) => {
                this.categoryList = res.data.list;
                if (this.editId) {
                    this.getEditInfo();
                }
            });
        },
        getBagCommandList() {
            getBagCommand().then((res) => {
                this.commandList = res.data;
            });
        },
        getEditInfo() {
            getBagInfo(this.editId)
                .then((res) => {
                    this.addForm.bag_name = res.data.bag_name;
                    this.addForm.category_id = res.data.category_id;
                    this.addForm.command_id = res.data.command_id;
                    this.addForm.in_type = JSON.stringify(res.data.in_type);
                    this.addForm.countdownMin = parseInt(res.data.countdown / 60);
                    this.addForm.countdownSec = res.data.countdown - this.addForm.countdownMin * 60;
                    this.addForm.script_data = res.data.getExtendData;
                    this.addForm.winning_number = res.data.winning_number;
                    this.getCate(res.data.category_id);
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        getCate(id) {
            this.categoryList.forEach((item) => {
                if (item.children) {
                    item.children.forEach((subItem) => {
                        if (subItem.category_id == id) {
                            this.addForm.selectCate = [item.category_id, id];
                        }
                    });
                }
            });
        },
        delRow(index) {
            this.addForm.script_data.splice(index, 1);
        },
        addScript() {
            let obj = {
                script_content: "",
                key_word: "",
                miss_key_word: "",
                answer_min: "",
                answer_sec: "",
                turnover_rate: "",
            };
            this.addForm.script_data.push(obj);
        },
        goBack() {
            this.$router.push("/bagManage/index");
        },
        changeCate(val) {
            let len = val.length;
            if (len > 0) {
                this.addForm.category_id = val[len - 1];
            } else {
                this.addForm.category_id = "";
            }
        },
        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let isErrNum = 0,
                        isErrLen = 0,
                        isErrKey = 0,
                        isErrMissKey = 0;
                    if (this.addForm.script_data.length == 0) {
                        this.$message.warning("请添加脚本话术！");
                        return;
                    }
                    this.addForm.script_data.forEach((item, index, arr) => {
                        if (!item.script_content) {
                            this.$refs[`${index}_script_content`].$el.classList.add("isError");
                            isErrNum = 1;
                        } else if (item.script_content.length < 2) {
                            this.$refs[`${index}_script_content`].$el.classList.add("isError");
                            isErrLen = 1;
                        } else if (item.script_content.length > 200) {
                            this.$refs[`${index}_script_content`].$el.classList.add("isError");
                            isErrLen = 1;
                        } else {
                            this.$refs[`${index}_script_content`].$el.classList.remove("isError");
                        }
                        if (item != arr[0]) {
                            if (!item.key_word) {
                                this.$refs[`${index}_key_word`].$el.classList.add("isError");
                                isErrNum = 1;
                            } else if (item.key_word.length < 2) {
                                this.$refs[`${index}_key_word`].$el.classList.add("isError");
                                isErrKey = 1;
                            } else if (item.key_word.length > 50) {
                                this.$refs[`${index}_key_word`].$el.classList.add("isError");
                                isErrKey = 1;
                            } else {
                                this.$refs[`${index}_key_word`].$el.classList.remove("isError");
                            }
                            if (!item.miss_key_word && item != arr[0]) {
                                this.$refs[`${index}_miss_key_word`].$el.classList.add("isError");
                                isErrNum = 1;
                            } else if (item.miss_key_word.length < 2) {
                                this.$refs[`${index}_miss_key_word`].$el.classList.add("isError");
                                isErrMissKey = 1;
                            } else if (item.miss_key_word.length > 200) {
                                this.$refs[`${index}_miss_key_word`].$el.classList.add("isError");
                                isErrMissKey = 1;
                            } else {
                                this.$refs[`${index}_miss_key_word`].$el.classList.remove("isError");
                            }
                        }
                    });
                    if (isErrNum == 1) {
                        this.$message.warning("每项为必填！");
                        return;
                    }
                    if (isErrLen == 1) {
                        this.$message.warning("请输入正确长度的脚本内容！");
                        return;
                    }
                    if (isErrKey == 1) {
                        this.$message.warning("请输入正确长度的关键词！");
                        return;
                    }
                    if (isErrMissKey == 1) {
                        this.$message.warning("请输入正确长度的错误关键词！");
                        return;
                    }
                    let params = {
                        category_id: this.addForm.category_id,
                        bag_name: this.addForm.bag_name,
                        in_type: Number(this.addForm.in_type),
                        command_id: this.addForm.command_id,
                        script_data: this.addForm.script_data,
                        winning_number: Number(this.addForm.winning_number),
                    };
                    params.countdown = 60 * this.addForm.countdownMin + this.addForm.countdownSec;
                    if (params.countdown == 0) {
                        this.$message.warning("倒计时必须大于0！");
                        return;
                    }
                    if (this.editId) {
                        params.id = this.editId;
                    }
                    saveBag(params)
                        .then((res) => {
                            this.$message.success(res.msg);
                            this.goBack();
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.bag-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    .el-scrollbar {
        margin: 20px;
        height: calc(100% - 40px);
    }

    ::v-deep .el-scrollbar__wrap {
        height: 100%;
        overflow-x: hidden;

        .el-scrollbar__view {
            height: 100%;
        }
    }

    ::v-deep .el-input-number .el-input__inner {
        text-align: left;
    }

    .top-form {
        margin-top: 39px;

        .el-form {
            margin-top: 30px;

            .el-input {
                width: 380px;
            }
        }
    }

    .act-box {
        width: 124px;
        height: 40px;
        background: #f0f3fc;
        border: 1px solid #dadce7;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .table-script {
        position: relative;

        .left-single {
            position: absolute;
            left: -40px;
            top: 12px;
            width: 20px;
            height: 20px;
            border-left: 1px solid #dadce7;
            border-bottom: 1px solid #dadce7;
        }
    }

    .inner-unit {
        margin: 0 10px;
    }

    .title-item {
        font-size: 16px;
        font-weight: 500;
        color: #060111;
        position: relative;
        padding-left: 16px;

        &:before {
            content: "";
            position: absolute;
            width: 6px;
            height: 12px;
            background: #2821fc;
            border-radius: 3px;
            left: 0;
            top: 5px;
        }
    }

    .script-content {
        margin-top: 74px;

        .el-table {
            margin-top: 20px;
            border: 3px solid #f7f8fc;

            ::v-deep th {
                &:nth-of-type(2) {
                    & > .cell {
                        padding-left: 40px;
                    }
                }
            }

            ::v-deep td {
                height: 60px;

                &:nth-of-type(2) {
                    & > .cell {
                        padding-left: 40px;
                    }
                }
            }
        }

        .footer-button {
            margin-top: 89px;
            text-align: center;
        }
    }
}
</style>
